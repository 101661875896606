window.$window = $(window);


/*
  レスポンシブ周り
 */
$(function () {
  var breakPoint, nowSize, pcFlag, setWidth, width;
  breakPoint = 768;
  width = 0;
  pcFlag = true;
  setWidth = function () {
    return width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  };
  setWidth();
  window.getWidth = function () {
    return width;
  };
  window.isPc = function () {
    return pcFlag;
  };
  window.isSp = function () {
    return !pcFlag;
  };
  window.getSize = function () {
    if (isPc()) {
      return 'pc';
    } else {
      return 'sp';
    }
  };
  window.getBreakPoint = function () {
    return breakPoint;
  };
  nowSize = getSize();
  return $window.on('resize', function (e) {
    var oldSize;
    oldSize = nowSize;
    setWidth();
    if (breakPoint < getWidth()) {
      pcFlag = true;
    } else {
      pcFlag = false;
    }
    if (oldSize !== (nowSize = getSize())) {
      return $window.trigger('responsive', [nowSize, oldSize]);
    }
  }).trigger('resize');
});


/*
    ページ内リンク
 */
$(function () {
  return $('a[href*="#"]').on('click', function (e) {
    let $elem, fullPath, hash, href, position, target, headerHeight;
    $elem = $(e.currentTarget);
    href = $elem.attr('href');
    fullPath = href.slice(0, href.indexOf('#'));
    hash = href.slice(href.indexOf('#'));
    if (location.origin + location.pathname === fullPath || fullPath === '') {
      target = $(hash === '#' ? 'html' : hash);
      if (!target.offset()) {
        // ReactRouterと衝突するので
        return true
      }
      e.preventDefault();
      // headerHeight = $('#header').height();
      if (isPc()) {
        headerHeight = $('#header').height();
      } else {
        headerHeight = $('.Header__content').height();
      }
      position = target.offset().top;
      position = position === 0 ? 0 : position - headerHeight;

      return $('html, body').stop().animate({
        scrollTop: position
      }, {
        duration: 400
      });

    }
  });
});


/*
  スクロールに応じた処理
 */
$(function () {

  let detailTabsNum = false;
  let detailTabsTop = 0;

  let scrollTop;
  let scrollStatus = 'no_scroll';
  // let footerOffsetTop = $footer.offset().top - $footer.height() - 22;
  let scrollTimeOut;
  let count = 0;
  let className1 = '-scrolled';
  let className2 = '-stopped';

  let topPage = false;
  let topHeaderFlag = false;

  $(window).on('scroll', function (e) {
    let $header = $('#header');
    let $footer = $('#footer');

    let $headerContent = $('.Header__content');
    let $detailTabs = $('.DetailTabs');

    if ($header.hasClass('-top')) {
      topPage = true;
    }

    // 詳細タブ
    detailTabsNum = $detailTabs.length;
    if (detailTabsNum) {
      detailTabsTop = $detailTabs.offset().top - $headerContent.height();
    }
    scrollTop = $(window).scrollTop();

    if (detailTabsNum && !$detailTabs.hasClass('-fixed')) {
      detailTabsTop = $detailTabs.offset().top - $headerContent.height();
    }

    // console.log(scrollTop, detailTabsTop);

    // ちょっとスクロールした
    if (100 < scrollTop) {
      if (scrollStatus == 'no_scroll') {
        scrollStatus = 'scrolled';
        $('body').addClass(className1)
      }
    } else {
      if (scrollStatus == 'scrolled') {
        scrollStatus = 'no_scroll';
        $('body').removeClass(className1)
      }
    }

    // PC,TOPページのみヘッダー切り替え
    if (topPage) {
      if (250 < scrollTop) {
        $header
          .removeClass('-scrollEnd')
          .removeClass('-topAnimationOut')
          .addClass('-topFixed')
          .addClass('-topAnimationIn')
        // $header[0].addEventListener("animationend",function(e){
        //   $header
        //     .removeClass('-topAnimationIn')
        // });
        topHeaderFlag = false;
      } else {
        if (!$header.hasClass('-scrollEnd')) {
          if (!topHeaderFlag) {
            topHeaderFlag = true;
            $header
              .removeClass('-topAnimationIn')
              .addClass('-topAnimationOut')
            $header[0].addEventListener("animationend", topHeaderAnimationCompete);
          }
        }
      }
    }
    function topHeaderAnimationCompete() {
      $header[0].removeEventListener("animationend", topHeaderAnimationCompete);
      $header
        .addClass('-scrollEnd')
        .removeClass('-topFixed')
        .removeClass('-topAnimationOut');
    }

    // SP用ヘッダー固定する
    if (28 < scrollTop) {
      $header.addClass('-fixed')
    } else {
      $header.removeClass('-fixed')
    }

    // 詳細タブを固定する
    if (detailTabsNum) {
      if (detailTabsTop < scrollTop) {
        $detailTabs.addClass('-fixed')
      } else {
        $detailTabs.removeClass('-fixed')
      }
    }

    // // スクロールが停止したら
    // clearTimeout( scrollTimeOut ) ;
    // scrollTimeOut = setTimeout( function () {
    //   $('body').addClass(className2)
    // }, 500 ) ;


  });

});



/*
  ヘッダーメニュー
 */
$(function () {
  // let $header = $('#header');
  // let $menuBtn = $('.js-headerMenu');
  // let $menuCloseBtn = $('.js-headerClose')

  // $menuBtn.on('click', function(){
  //   if($header.hasClass('-open')){
  //     $header.removeClass('-open');
  //   }else{
  //     $header.addClass('-open');
  //   }
  // });
  $('.js-headerMenu').on('click', el => {
    let $header = $('#header')
    if ($header.hasClass('-open')) {
      $header.removeClass('-open');
    } else {
      $header.addClass('-open');
    }
  })

  $(document).on('click', el => {
    let $header = $('#header')
    let $tar = el.target
    let notMenuFlg = !$('.Header__member').has($tar).length
    if (notMenuFlg && $header.hasClass('-open')) {
      $header.removeClass('-open')
    }
  })

  // if($menuCloseBtn.length){
  //   $menuCloseBtn.on('click', function(){
  //     if($header.hasClass('-open')){
  //       $header.removeClass('-open');
  //     }
  //   });
  // }
  $(document).on('click', '.js-headerClose', el => {
    let $menuCloseBtn = $('.js-headerClose')
    let $header = $('#header')
    if ($menuCloseBtn.length) {
      if ($header.hasClass('-open')) {
        $header.removeClass('-open');
      }
    }
  })
});


/*
  ヘッダーが横スクロールできるようにする
*/

$(function () {
  $(window).on("scroll", function () {

    if (isPc()) {
      $(".Header__inner").css({
        "left": -$(window).scrollLeft()
      });

      let $site_type = $("input[name='site_type']").val()
      let $detailTabsInner = $('.DetailTabs__inner');
      let detailTabsInnerNum = false;

      // 詳細タブ
      detailTabsInnerNum = $detailTabsInner.length;
      if (detailTabsInnerNum) {
        $detailTabsInner.css({
          "left": -$(window).scrollLeft()
        });
      }



    }

  });
});


/*
  タブメニュー
 */
$(function () {
  $(document).on('click', '.js-searchTab', el => {
    let $searchTab = $('.js-searchTab');
    let $searchContent = $('.js-searchContent');

    if ($searchTab.length) {
      const $el = $(el.currentTarget)
      let index = $searchTab.index($el);
      // console.log(index);
      if (!$el.hasClass('-active')) {
        $searchTab
          .removeClass('-active')
          .eq(index)
          .addClass('-active')
        $searchContent
          .removeClass('-active')
          .eq(index)
          .addClass('-active')
      }
    }
  })
});




/*
  SLICK
 */
$(document).on('turbolinks:load', () => {
  let $slider1 = $('.js-slider1');
  let slide1Num = $slider1.length;

  if (slide1Num) {

    $slider1.not('.slick-initialized').slick({
      // centerMode:false,
      centerMode: true,
      infinite: true,
      centerPadding: '263px',
      dots: true,
      responsive: [
        {
          breakpoint: 767, //767px以下のサイズに適用
          settings: {
            centerPadding: '24%',
          }
        }
      ]
    });
  }

  let $slide2 = $('.js-slider2');
  let slide2Num = $slide2.length;
  if (slide2Num) {

    $slide2.not('.slick-initialized').slick({

      infinite: true,
      centerMode: true,
      variableWidth: true,
      // centerPadding:'353px',
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      responsive: [
        {
          breakpoint: 767, //767px以下のサイズに適用
          settings: {
            // centerPadding:'24%',
          }
        }
      ]
    });
  }
});

// turbolinks対策
// See: https://github.com/bodrovis/jquery-slick-rails#turbolinks-gotcha
$(document).on('turbolinks:before-cache', () => {
  $('.js-slider1, .js-slider2').not('.slick-initialized').slick('unslick');
});



/*
  object-fit for IE
*/
$(function () {
  if (isIE() || isEedge()) {
    // console.log('IE');
    objectFitImages('img.ImageOjectFit');
  }
});

/* IE,Eedge */
function isIE() {
  let userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
    return true;
  }
  return false;
}
function isEedge() {
  let userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('edge') != -1) {
    return true;
  }
  return false;
}

// ランキング検索は二次開発
$('.-searchRankBtn').on('click', ev => {
  let text = '働きやすい事務所のランキングは近日中公開する予定です'
  const $tar = $(ev.currentTarget)
  const $select = $tar.parents('form').find('select')
  let $site_name = $("input[name='site_name']").val()
  if ($select.val()) {
    let url = `/company_infos/${$site_name}/${$select.val()}`;
    location.href = url
    // const $option = $select.find('option:selected')
    // text = $option.text() + 'の' + text
    // alert(text)
  } else {
    alert('都道府県を選んでください')
  }
})

// データ足りない方をクリックした時
$(document).on('click', '.-notRankedHosp', ev => {
  let text = '働きやすい事務所のランキングは近日中公開する予定です'
  const $tar = $(ev.currentTarget)
  const prefJa = $tar.data('pref-ja')
  if (prefJa) {
    text = prefJa + 'の' + text
    alert(text)
  }
})
